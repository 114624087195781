


































































































import EditBankAccountNumber from "@/components/settings/stripeSettings/EditBankAccountNumber.vue";
import { computed, defineComponent } from "@vue/composition-api";
import { uiModule } from "@/store/modules/ui";
import { userModule } from "@/store/modules/user";
import AboutMe from "@/components/settings/AboutMe.vue";
import DeleteAccount from "@/components/settings/DeleteAccount.vue";
import EditProfile from "@/components/profile/EditProfile.vue";
import EmailAndPassword from "@/components/settings/EmailAndPassword.vue";
// import Notifications from "@/components/settings/notifications/Notifications.vue";
import UploadDocumentation from "@/components/settings/upload/UploadDocumentation.vue";

export default defineComponent({
  name: "Settings",
  components: {
    EditBankAccountNumber,
    AboutMe,
    DeleteAccount,
    EditProfile,
    EmailAndPassword,
    // Notifications,
    UploadDocumentation,
  },
  setup() {
    return {
      isSeller: computed(() => userModule.state.user.role === "seller"),
      isStudent: computed(() => userModule.state.user.role === "student"),
      projectCount: computed(() => uiModule.state.projectCount),
    };
  },
});
