



























import handleError from "@/helpers/errors";
import rules from "@/helpers/formRules";
import { useNotifier } from "@/providers/notifier";
import { defineComponent, onMounted, reactive } from "@vue/composition-api";
import { userModule } from "@/store/modules/user";

export default defineComponent({
  name: "EditBankAccountNumber",
  setup() {
    const notify = useNotifier();
    const vm = reactive({
      bankAccountNumber: "",
      loading: false,
    });

    onMounted(() => {
      vm.bankAccountNumber = userModule.state.account.bankAccountNumber;
    });

    async function save() {
      vm.loading = true;
      if (rules.bankAccountNumber(vm.bankAccountNumber) === true) {
        try {
          await userModule.actions.updateUserAccount({
            bankAccountNumber: vm.bankAccountNumber,
          });
          notify({
            title: "Oppdatert! 🥳",
            message: "",
            type: "success",
          });
        }
        catch (e) {
          handleError(e);
          notify({
            title: "Klarte ikke oppdatere kontonummer 🤯",
            message: "Kontakt support hvis problemet vedvarer.",
            type: "error",
          });
        }
      }
      else {
        notify({
          title: "Pass på at det er skrevet riktig. 🤔",
          message: "Du kan ikke bruke punktim eller andre skilletegn.",
          type: "error",
        });
      }
      vm.loading = false;
    }

    return {
      rules,
      save,
      vm,
    };
  },
});

