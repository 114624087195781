






































































import { deleteUser } from "@/api/authService";
import { useNotifier } from "@/providers/notifier";
import { auth } from "@/services/firebase";
import { userModule } from "@/store/modules/user";
import { computed, defineComponent, PropType, reactive, ref, watchEffect } from "@vue/composition-api";

export default defineComponent({
  name: "DeleteAccountDialog",
  props: {
    value: {
      type: Boolean as PropType<boolean>,
      required: true,
    },
  },
  setup(props, { emit }) {
    const _value = ref(props.value);
    watchEffect(() => {
      emit("input", _value);
    });
    const vm = reactive({
      loading: false,
    });
    const notify = useNotifier();
    const seller = computed(() => userModule.state.user.role === "seller");
    const deleteAccount = async() => {
      try {
        vm.loading = true;
        await deleteUser(userModule.state.userId);
        await auth.signOut();
        const event = new Event("deletedAccount");
        document.dispatchEvent(event);
      }
      catch (e) {
        notify({
          title: "Ooops! Noe gikk galt. 🤯",
          message: "Vi klarte ikke å slette brukeren. Kontakt oss på chat hvis problemet vedvarer.",
          type: "error",
        });
      }
      vm.loading = false;
    };

    return {
      _value,
      deleteAccount,
      seller,
      vm,
    };
  },
});
