

















import DeleteAccountDialog from "@/components/settings/DeleteAccountDialog.vue";
import { defineComponent, reactive } from "@vue/composition-api";

export default defineComponent({
  name: "DeleteAccount",
  components: { DeleteAccountDialog },
  setup() {
    const vm = reactive({
      deleteDialogVisible: false,
    });
    const showDeletionDialog = (): void => {
      vm.deleteDialogVisible = true;
    };
    return {
      showDeletionDialog,
      vm,
    };
  },
});
