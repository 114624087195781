
































































import { updateEmail } from "@/api/userService";
import formRules from "@/helpers/formRules";
import { isPlaceholderEmail } from "@/helpers/stringUtils";
import { userModule } from "@/store/modules/user";
import { updateAuthObject } from "@/api/authService";
import { useNotifier } from "@/providers/notifier";
import { defineComponent, onMounted, reactive } from "@vue/composition-api";

export default defineComponent({
  name: "EmailAndPassword",
  setup() {
    const vm = reactive({
      email: "",
      loading: false,
      password: "",
      repeatPassword: "",
      validForm: false,
    });

    const notify = useNotifier();

    onMounted(() => {
      if (!isPlaceholderEmail(userModule.state.account.email)) {
        vm.email = userModule.state.account.email;
      }
    });

    async function updateEmailAndPassword() {
      vm.loading = true;
      try {
        if (vm.email && vm.email !== userModule.state.account.email && !isPlaceholderEmail(vm.email)) await updateEmail(userModule.state.userId, vm.email);
        if (vm.password.length > 7 && vm.password === vm.repeatPassword) await updateAuthObject({ password: vm.password, uid: userModule.state.userId });

        notify({
          title: "E-post og passord oppdatert",
          message: "",
          type: "success",
        });
      }
      catch (e) {
        notify({
          title: "Oops!",
          message: "Noe gikk galt. Ta kontakt med support om problemet vedvarer",
          type: "error",
        });
      }
      vm.loading = false;
    }

    return {
      formRules,
      updateEmailAndPassword,
      vm,
    };
  },
});

